import clockIcon from '../../resources/icons/clock.svg'
import { Link, useOutletContext } from 'react-router-dom'
import './Booking.css'
import { Button } from '@mui/material'

const Booking = () => {
  const { reservationId } = useOutletContext()
  return (
    <div className='book-container'>
      <div className='instructions-container'>
        <span className='heading'>Let&apos;s get this party started.</span>
        <span>The following information will be collected to calculate your cost estimate. </span>
        <ul>
          <li>Full venue address</li>
          <li>Event date</li>
          <li>Start and end time of event</li>
          <li>Anticipated guest count</li>
          <li>Desired service</li>
          <li>Type of menu (You&apos;ll have the chance to select specific products after confirmed.)</li>
        </ul>
      </div>
      <div className='booking-btn-group'>
        <Link to={reservationId}>
          <Button variant='contained'>Start Reservation</Button>
        </Link>
        <div className='estimated-time'>
          <img src={clockIcon} />
          <span>Takes 10+ minutes</span>
        </div>
      </div>
      <span className='caption'>
        We prioritize your privacy and security. Rest assured, no information will be retained without your explicit confirmation.
      </span>
    </div>
  )
}

export default Booking
