import { Chip, Divider } from '@mui/material'
import './Payment.css'
import dayjs from 'dayjs'
import Policy from '../Policy/Policy'
import Subtotal from '../CostEstimate/Subtotal'
import PayForm from './PaymentForms/PayForm'
import { useOutletContext } from 'react-router-dom'

const Payment = ({ createPayment }) => {
  const { paymentMethod, setPaymentMethod, eventDate, order } = useOutletContext()
  const twoWeeksPrior = dayjs(eventDate).subtract(2, 'week').format('MM/DD/YYYY')

  const props = {
    isPaying: true,
    eventDate,
    paymentMethod,
    order: order.order
  }
  return (
    <div className='form-container'>
      <div className='methods-container'>
        <span className='pay-method'>How would you like to pay?</span>
        <div className='payment-options'>
          <Chip
            label='Pay in Full'
            variant={paymentMethod === 'full' ? 'contained' : 'outlined'}
            color={'primary'}
            clickable
            onClick={() => {
              setPaymentMethod('full')
            }}
          />
          {dayjs().isBefore(twoWeeksPrior) && (
            <Chip
              label='Deposit 30%'
              variant={paymentMethod === 'deposit' ? 'contained' : 'outlined'}
              color={'primary'}
              clickable
              onClick={() => {
                setPaymentMethod('deposit')
              }}
            />
          )}
        </div>
      </div>

      <Subtotal {...props} />
      {createPayment && <PayForm />}
      <div style={{ width: '100%', margin: '0 auto', textAlign: 'left' }}>
        <span style={{ textAlign: 'left', fontSize: '14px' }}>
          By clicking, you agree to the booking terms, payment schedule, and to receive emails and texts from Mixing Mo&apos;s. Unsubscribe
          anytime.
        </span>
      </div>
      <Divider />
      <div id='policy'>
        <Policy />
      </div>
    </div>
  )
}

export default Payment
