import { Drawer, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import gaMap from '../resources/middle-ga.svg'
import './Map.css'

const Map = ({ open, setOpen }) => {
  return (
    <div>
      <Drawer anchor='bottom' open={open} sx={{ display: 'flex', flexDirection: 'column', padding: '2rem' }}>
        <div className='drawer'>
          <div className='close-icon'>
            <IconButton onClick={() => setOpen(!open)}>
              <CloseIcon color='primary' fontSize='large' />
            </IconButton>
          </div>
          <span className='heading'>Standard Travel</span>
          <span className='heading3'>Up to 30 miles</span>
          <span>
            Please note that long-distance fees will apply to account for mileage, transportation, and travel expenses. These fees are
            necessary to ensure that we can provide reliable service while covering the costs associated with extended travel distances.
          </span>
          <div className='map-div'>
            <img src={gaMap} alt='ga-map' />
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default Map
