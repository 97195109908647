import { useRouteError } from 'react-router-dom'
import './ErrorPage.css'
import errorIcon from '../resources/undraw/error-draw.svg'
import logo from '../resources/logo/logo.svg'
import { Button } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'

const ErrorPage = () => {
  const error = useRouteError()
  console.log(error)
  return (
    <div id='error-page'>
      <div className='error-container'>
        <Button href={`${process.env.REACT_APP_CLIENT_URL}/`}>
          <div className='error-logo-container'>
            <img src={logo} />
          </div>
        </Button>
        <div className='error-draw'>
          <img src={errorIcon} alt='404-icon' />
        </div>
        <div className='error-header'>
          <span className='error-title'>Oops!</span>
          <span className='error-description'>We&apos;re sorry, something went wrong. Please refresh your page and try again.</span>
        </div>
        <Button size='large' variant='contained' startIcon={<EmailIcon />} href='mailto:mo@mixingmos.com'>
          Send An Email Instead
        </Button>
        {console.log(error.statusText || error.message)}
      </div>
    </div>
  )
}

export default ErrorPage
