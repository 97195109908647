import calendar from '../resources/icons/calendar.svg'
import location from '../resources/icons/location2.svg'
import clock from '../resources/icons/clock2.svg'
import guests from '../resources/icons/guests.svg'
import dayjs from 'dayjs'
import './EventDetails.css'

const EventDetails = (eventProps) => {
  const eventAddress = eventProps.eventAddress
  const eventDate = eventProps.eventDate
  const guestCount = eventProps.guestCount
  const eventStart = dayjs(eventProps.eventStart)
  const eventEnd = dayjs(eventProps.eventEnd)
  return (
    <div className='event-details'>
      <div className='icon-detail'>
        <img src={location} alt='location-icon' />
        <span>{eventAddress}</span>
      </div>
      <div className='icon-detail'>
        <img src={calendar} alt='calendar-icon' />
        <span>{new Date(eventDate).toDateString()}</span>
      </div>
      <div className='icon-detail'>
        <img src={clock} alt='clock-icon' />
        <span>
          {eventStart.format('hh:mmA')} - {eventEnd.format('hh:mmA')} (
          {eventEnd.get('hours') -
            eventStart.get('hours') +
            ' hours' +
            (eventEnd.get('minutes') - eventStart.get('minutes') !== 0
              ? ',' + eventEnd.get('minutes') - eventStart.get('minutes') + ' minutes'
              : '')}
          )
        </span>
      </div>
      <div className='icon-detail'>
        <img src={guests} alt='guest-icon' />
        <span>{guestCount + ' guests'}</span>
      </div>
    </div>
  )
}

export default EventDetails
