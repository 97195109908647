import { motion } from 'framer-motion'
import './ThreeDotsWave.css'

const loadingDivVariants = {
  start: {
    transition: {
      staggerChildren: 0.1
    }
  },
  end: {
    transition: {
      staggerChildren: 0.1
    }
  }
}

const loadingCircleVariants = {
  start: {
    y: '0%'
  },
  end: {
    y: '100%'
  }
}

const loadingCircleTransition = {
  duration: 1,
  repeat: Infinity,
  ease: 'easeInOut'
}

export default function ThreeDotsWave() {
  return (
    <motion.div className='circle-div' variants={loadingDivVariants} initial='start' animate='end'>
      <motion.span className='loadingCircle' variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span className='loadingCircle' variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span className='loadingCircle' variants={loadingCircleVariants} transition={loadingCircleTransition} />
    </motion.div>
  )
}
