import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk'
import './PayForm.css'
import { useOutletContext } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import ThreeDotsWave from '../ThreeDotsWave.js'
import Info from '../../Messages/Info'

const PayForm = () => {
  const {
    paymentMethod,
    first,
    last,
    email,
    lineItems,
    reservationId,
    eventAddress,
    eventDate,
    netDue,
    guestCount,
    service,
    menu,
    phone,
    eventStart,
    eventEnd,
    comments,
    setOrder,
    customer
  } = useOutletContext()
  const [isReady, setIsReady] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [reservation, setReservation] = useState({
    reservationId: reservationId,
    eventAddress,
    eventDate,
    eventStart,
    eventEnd,
    guestCount,
    service,
    menu,
    netDue,
    paymentMethod,
    createdDate: dayjs(),
    first,
    last,
    email,
    phone,
    comments,
    customerId: customer.id,
    orderId,
    lineItems
  })
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(null)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    const createTransaction = async () => {
      if (customer) {
        try {
          if (!orderId) {
            const orderResponse = await axios.post(`${process.env.REACT_APP_API_URL}/order`, {
              customerId: customer.id, // Use the created customer ID here
              serviceCharges: [],
              lineItems
            })
            const newOrderId = orderResponse.data.order.id
            setReservation((prev) => ({
              ...prev,
              orderId: newOrderId
            }))
            setOrderId(newOrderId)
            setOrder(orderResponse.data)
          }
        } catch (error) {
          setError(true)
        }
      }
    }
    createTransaction()
    setIsReady(true)
  }, [])

  const createPayment = async (sourceId, amt, verificationToken) => {
    const paymentResponse = await axios
      .post(`${process.env.REACT_APP_API_URL}/payment`, {
        sourceId,
        amt,
        customerId: customer.id,
        orderId,
        verificationToken
      })
      .catch(() => setError(true))
    return paymentResponse.data.response.payment
  }

  const createInvoice = async (cardId) => {
    const serviceDate = dayjs(eventDate).format('YYYY-MM-DD')
    const balanceDue = dayjs(eventDate).subtract(2, 'week').format('YYYY-MM-DD')
    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}/invoice`, {
        customerId: customer.id,
        orderId,
        balanceDue,
        serviceDate,
        cardId
      })
      .catch(() => setError(true))
    const publishedInvoice = await axios
      .post(`${process.env.REACT_APP_API_URL}/invoice/publish/${response.data.id}`)
      .catch(() => setError(true))
    return publishedInvoice.data
  }

  useEffect(() => {
    const saveReservation = async (body) => {
      return await axios.post(`${process.env.REACT_APP_API_URL}/reservation`, body).then((error) => console.error(error))
    }

    const createGoogleEvent = async () => {
      const date = dayjs(eventDate)
      const start = dayjs(eventStart)
      const end = dayjs(eventEnd)
      const startTime = dayjs(date).set('hour', start.get('hour')).set('minutes', start.get('minutes'))
      const endTime = dayjs(date).set('hour', end.get('hour')).set('minutes', end.get('minutes'))
      return await axios
        .post(`${process.env.REACT_APP_API_URL}/scheduleEvent`, {
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          summary: `${service.title} - ${menu.name} Menu`,
          description: service.description,
          location: eventAddress
        })
        .catch(() => setError(true))
    }

    function formatMoney(number) {
      // Divide by 100 to get the correct decimal place
      const amount = number / 100

      // Format the number to a string with commas and two decimal places
      return amount.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }

    const sendEmail = async (reservation) => {
      const orderResponse = await axios.get(`${process.env.REACT_APP_API_URL}/order/${orderId}`).catch(() => setError(true))
      const order = orderResponse.data.order
      const body = {
        link: `${process.env.REACT_APP_CLIENT_URL}/dashboard/${reservationId}`,
        date: dayjs(reservation.eventDate).format('MM/DD/YYYY'),
        start: dayjs(reservation.eventStart).format('hh:mm A'),
        end: dayjs(reservation.eventEnd).format('hh:mm A'),
        address: reservation.eventAddress,
        guests: reservation.guestCount,
        service: reservation.service?.title,
        menu: reservation.menu?.name,
        comments: reservation.comments,
        total: `$${formatMoney(order?.totalMoney.amount)}`,
        amount: `$${formatMoney(order?.tenders[0].amountMoney.amount)}`,
        remaining: `$${formatMoney(order?.netAmountDueMoney.amount)}`,
        details: `${order?.tenders[0].cardDetails.card.cardBrand} ending in ${order?.tenders[0].cardDetails.card.last4}`,
        email: reservation.email
      }
      return await axios.post(`${process.env.REACT_APP_API_URL}/email/confirmation`, body).catch(() => setError(true))
    }
    if (success) {
      const reservationResponse = saveReservation(reservation)
      if (process.env.REACT_APP_VERCEL_ENV !== 'dev') {
        sendEmail(reservationResponse.data)
        createGoogleEvent()
      }
      window.location.replace(`${process.env.REACT_APP_CLIENT_URL}/confirmed/${reservationId}`)
    }
  }, [success])
  const handleClick = () => {
    setLoading(true)
  }

  const handleDeposit = async (token, verifiedBuyer) => {
    const cardOnFile = await axios
      .post(`${process.env.REACT_APP_API_URL}/card`, {
        sourceId: token.token,
        verificationToken: verifiedBuyer.token,
        card: {
          ...token.details.card,
          customerId: customer.id
        }
      })
      .catch(() => setError(true))
    const invoice = await createInvoice(cardOnFile.data.id)
    const depositAmount = invoice.paymentRequests[0].totalCompletedAmountMoney.amount
    const payment = invoice.paymentRequests[0]
    //  Create reservation
    setReservation((prev) => ({
      ...prev,
      payments: [
        ...(prev.payments || []), // Spread existing payments if they exist, or start with an empty array
        {
          id: payment.uid,
          date: payment.dueDate,
          description: 'Deposit',
          amount: depositAmount,
          receiptUrl: invoice.publicUrl
        }
      ]
    }))
    if (invoice.paymentRequests?.[0].computedAmountMoney.amount > 0) {
      setSuccess(true)
    } else {
      setLoading(false)
    }
  }

  const handlePayment = async (token, verifiedBuyer) => {
    const payment = await createPayment(token.token, netDue, verifiedBuyer.token).catch(() => setError(true))

    if (payment) {
      setReservation((prev) => ({
        ...prev,
        payments: [
          ...(prev.payments || []), // Spread existing payments if they exist, or start with an empty array
          {
            id: payment.id,
            date: payment.createdAt,
            description: 'Full payment',
            amount: payment.totalMoney.amount,
            receiptUrl: payment.receiptUrl
          }
        ]
      }))
      const status = payment.status
      if (status === 'COMPLETED') {
        setSuccess(true)
      } else {
        setLoading(false)
        setError(true)
      }
    }
  }
  return (
    <div>
      {isReady && (
        <PaymentForm
          createVerificationDetails={() => ({
            intent: 'CHARGE_AND_STORE',
            currencyCode: 'USD',
            amount: netDue,
            billingContact: {
              familyName: first,
              givenName: last,
              email: email
            }
          })}
          applicationId={process.env.REACT_APP_SQUARE_APP_ID}
          locationId={process.env.REACT_APP_SQUARE_LOCATION}
          cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
            if (paymentMethod === 'deposit') {
              await handleDeposit(token, verifiedBuyer)
            } else {
              await handlePayment(token, verifiedBuyer)
            }
          }}
        >
          <div className='pay-btns'>
            {error}
            <CreditCard
              buttonProps={{
                onClick: handleClick,
                css: {
                  backgroundColor: 'var(--primary-main)',
                  fontSize: '14px',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: 'var(--primary-light)'
                  }
                }
              }}
            />
          </div>
          {error && (
            <div style={{ marginTop: '1rem' }}>
              <Info
                notification={{
                  title: 'Something went wrong',
                  message: 'Your payment could not be processed. Please try again.',
                  type: 'error'
                }}
              />
            </div>
          )}

          {loading && <ThreeDotsWave />}
        </PaymentForm>
      )}
    </div>
  )
}

export default PayForm
