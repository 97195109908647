import fbIcon from '../../resources/icons/fb-icon.svg'
import tikokIcon from '../../resources/icons/tiktok-icon.svg'
import igIcon from '../../resources/icons/ig-icon.svg'
import mailIcon from '../../resources/icons/mail-icon.svg'
import './SocialMediaIcons.css'

const SocialMediaIcons = () => {
  const socialMediaIcons = [
    {
      icon: fbIcon,
      url: 'https://www.facebook.com/profile.php?id=61560862936813&mibextid=LQQJ4d'
    },
    {
      icon: tikokIcon,
      url: 'https://www.tiktok.com/@mixingmos?_t=8pnHy3Rdj7X&_r=1'
    },
    {
      icon: igIcon,
      url: 'https://www.instagram.com/mixingmos?igsh=MTlwdXc3cHZmM2dubA%3D%3D&utm_source=qr'
    },
    {
      icon: mailIcon,
      url: 'mailto:mo@mixingmos.com'
    }
  ]

  return (
    <div className='iconsContainer'>
      {socialMediaIcons.map((item, index) => {
        return (
          <div className={'icon'} key={'icon' + index} onClick={() => window.open(item.url, '_blank')}>
            <img src={item.icon} />
          </div>
        )
      })}
    </div>
  )
}

export default SocialMediaIcons
