import axios from 'axios'
import { useEffect, useState } from 'react'
import Carousel from 'react-gallery-carousel'
import 'react-gallery-carousel/dist/index.css'
import './Gallery.css'
const Gallery = () => {
  const [images, setImages] = useState([])

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/images`)
        const imgObjects = await res.data
        if (Array.isArray(imgObjects)) {
          const fetchImage = imgObjects.map((i) =>
            fetch(`${process.env.REACT_APP_API_URL}/images/${i.id}`).then((response) => response.blob())
          )
          const imageBlobs = await Promise.all(fetchImage)
          const images = imageBlobs.map((blob) => {
            return { src: URL.createObjectURL(blob) }
          })
          setImages(images)
        } else {
          console.error('Expected an array but received:', imgObjects)
        }
      } catch (error) {
        console.error('Error fetching images:', error)
      }
    }
    fetchImages()
  }, [])

  return (
    <div className='gallery'>
      {images && (
        <Carousel
          className='carousol'
          images={images}
          isLoop
          shouldLazyLoad
          isAutoPlaying
          hasTransition
          hasMediaButton={false}
          // leftIcon={<img src={leftArrow} alt="left-arrow" />}
          // rightIcon={<img src={rightArrow} alt="left-arrow" />}
          // style={{ width: '100%' }}
        />
      )}
    </div>
  )
}

export default Gallery
