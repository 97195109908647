import SectionHeader from '../pages/Booking/SectionHeader'
import clock from '../resources/icons/clock2.svg'
import './EventDuration.css'
import { useOutletContext } from 'react-router-dom'
import { TimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Button, Divider, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useState } from 'react'
import Info from '../Messages/Info'
import CloseIcon from '@mui/icons-material/Close'

const EventDuration = () => {
  const context = useOutletContext()
  const eventStart = context.eventStart
  const setEventStart = context.setEventStart
  const eventEnd = context.eventEnd
  const setEventEnd = context.setEventEnd
  const handleNextPage = context.handleNextPage
  const { eventDate } = useOutletContext()
  const eventDateObj = dayjs(eventDate)
  const [duration, setDuration] = useState(3)
  const [view, setView] = useState('standard')
  const [isEditing, setIsEditing] = useState(false)

  const estimateTime = (value) => {
    const startHour = eventStart.get('hour')
    const startMinutes = eventStart.get('minutes')
    let endHour = eventEnd.get('hour')
    const endMinutes = eventEnd.get('minutes')
    const calculatedTime = dayjs()
    switch (value) {
      case 'arrival':
        return calculatedTime
          .set('hour', startHour - 1)
          .set('minutes', startMinutes - 30)
          .format('LT')
      case 'open':
        return calculatedTime.set('hour', startHour).set('minutes', startMinutes).format('LT')
      case 'last-call':
        return calculatedTime
          .set('hour', endHour)
          .set('minutes', endMinutes - 30)
          .format('LT')
      case 'close':
        return calculatedTime.set('hour', endHour).set('minutes', endMinutes).format('LT')
    }
  }

  const handleChange = (e) => {
    const value = e.target.value
    setIsEditing(true)
    setView(value)
    if (value === 'extended') {
      setDuration(4)
      setEventEnd(dayjs(eventStart.add(4, 'hour')))
    } else {
      setEventEnd(dayjs(eventStart.add(3, 'hour')))
      setDuration(3)
    }
  }

  return (
    <div className='form-container'>
      <SectionHeader icon={clock} instruction={'How long is your event?*'} />
      <Divider />
      <span className='header-description'>Standard service is 3 hours. Additional time is subject to a surcharge.</span>
      <div className='duration-details'>
        <div className='time-container'>
          <TimePicker
            label='Event Start'
            value={eventStart}
            onChange={(value) => {
              let end = dayjs(value).add(duration, 'hour')
              setEventStart(value)
              setEventEnd(end)
            }}
            minTime={dayjs(new Date(eventDateObj.get('year'), eventDateObj.get('month'), eventDateObj.get('day'), 17))}
            maxTime={dayjs(new Date(eventDateObj.get('year'), eventDateObj.get('month'), eventDateObj.get('day'), 21))}
            autoFocus={true}
          />
          {isEditing && (
            <IconButton
              color='error'
              onClick={() => {
                const date = dayjs(new Date(eventDateObj.get('year'), eventDateObj.get('month'), eventDateObj.get('day')))
                const startTime = date.hour(17).minute(0)
                const endTime = startTime.add(3, 'hour')
                setEventStart(startTime)
                setEventEnd(endTime)
                setDuration(3)
                setView('standard')
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <ToggleButtonGroup value={view} exclusive onChange={handleChange} color='primary'>
          <ToggleButton value='standard'>
            {/* <AddIcon /> */}
            Standard (3 hrs)
          </ToggleButton>
          <ToggleButton value='extended'>
            {/* <AddIcon /> */}
            Extended (4 hrs)
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <Divider />
      <div className='time-estimates'>
        <span className='header-description'>Estimated arrival:</span>
        <span className='time'>{estimateTime('arrival')}</span>
        <span className='header-description'>Bar open: </span>
        <span className='time'>{estimateTime('open')}</span>
        <span className='header-description'>Last call for alcohol:</span>
        <span className='time'>{estimateTime('last-call')}</span>
        <span className='header-description'>Bar closure:</span>
        <span className='time'>{estimateTime('close')}</span>
      </div>
      {view === 'extended' && (
        <div>
          <Info
            notification={{
              title: 'Extended service request',
              message: 'More than three hours of service is subject to a surcharge.',
              type: 'info'
            }}
          />
        </div>
      )}
      <Button variant='contained' fullWidth onClick={handleNextPage}>
        Continue
      </Button>
    </div>
  )
}

export default EventDuration
