export const policies = [
  {
    title: 'Cancellation & Refund',
    description: 'Free cancellation for 24 hours. Please note that deposits are non-refundable and applied to total balance.'
  },
  {
    title: 'Remaining Balance',
    description:
      'Total balance must be paid no later than 14 days prior to event. By signing the agreement, you authorize use of the card on file for remaining balance. Late payments are subject to a $50 fee per day for up to 3 days. The right to refuse service due to unpaid balance is reserved.'
  },
  {
    title: 'Modifications to Contract',
    description:
      'Any modifications, additions, deletions, or stipulations shall not be effective unless approved in writing by both parties to this contract. This includes, but is not limited to: event location, date, duration, number of attendees, services rendered, and bar menu to be served.'
  },
  {
    title: 'Impossibility of Service',
    description:
      'Neither party shall be liable for any delays or losses due to acts of God, war, riot, governmental actions, disasters, strikes (by either party), civil disorder, or other emergencies making it inadvisable, illegal or impractical to provide service.'
  },
  {
    title: 'Damages or Injury',
    description: 'The client assumes responsibility for damages to bartending equipment or bodily injury to staff if deemed responsible.'
  },
  {
    title: 'Client Responsibility',
    description:
      'Client will be responsible for prohibiting attempts at self-service of alcoholic drinks, removing invitees and others from the premises for any action or behavior deemed contrary to the terms of service, and ensuring that invitees and others abide by State liquor laws. Client must purchase all liquor, beer, and wine. Soft drinks and other non-alcoholic mixers, garnish, and syrups are provided. Contractor is not responsible for any arrangement made for pick up after event. Contractor will not be responsible for any alcohol left on premises before or after the event.'
  },
  {
    title: 'Waiver of Liability',
    description:
      'Client agrees to defend, indemnify and hold harmless from any liability, claims, demands, actions and causes of action arising out of or related to any loss, damage, illness, death, or injury sustained by invitees and others in connection with the service and consumption of alcoholic beverages at the event irrevocably and unconditionally. Contractor is obligated to terminate the event if invitees and others do not comply with the State liquor laws and/or any policies set forth in this contract concerning service of beverages or conduct of invitees or others. Client understands that it is against the law for persons at the event under the age of 21 to consume alcohol and Client will monitor them appropriately. In the event Contractor becomes unavailable due to storm damage, civil unrest, Act of God, health concerns, pandemic restrictions on gatherings or reasons not controlled by Owner and/ or Bartender, this contract will be canceled, and Contractor will not be obligated or held responsible for any further damages or losses. If such an event occurs, the retention fee made at signing would be refunded.'
  },
  {
    title: 'Consent to Photography',
    description:
      'By agreeing to the terms of this contract, the Client acknowledges and consents to the Contractors right to capture photographic images and videos of the completed work, including any elements of the Clients property that are relevant to the project. The Client grants the Contractor a non-exclusive, worldwide, royalty-free license to use, reproduce, display, and distribute these images for promotional and portfolio purposes. The Client understands that these images may be used in various media formats, including but not limited to, print, digital, and online platforms. The Client waives any right to inspect or approve the final use of these images and releases the Contractor from any liability arising from their use. This provision shall remain in effect indefinitely, even after the completion or termination of this contract'
  }
]
