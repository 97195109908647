import { useEffect, useState } from 'react'
import logo from '../../resources/logo/logo.svg'
import menu from '../../resources/icons/menu.svg'
import close from '../../resources/icons/close.svg'
import { Link as LinkS } from 'react-scroll'
import './AppBar.css'
import { Button, IconButton } from '@mui/material'
import SocialMediaIcons from '../Menu/SocialMediaIcons'

const AppBar = () => {
  const [active, setActive] = useState('Home')
  const [isOpen, setIsOpen] = useState(false)
  const [showAppBar, setShowAppBar] = useState(true)
  let lastScrollY = 0

  const menuItems = ['Home', 'How it Works', 'Services', 'Portfolio']

  const sideBarHandler = () => {
    setIsOpen(!isOpen)
  }
  const handleSetActive = (item) => {
    setActive(item)
    if (isOpen) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== 'undefined') {
        const currentScrollY = window.scrollY
        setShowAppBar(currentScrollY < lastScrollY || currentScrollY < 100)
        lastScrollY = currentScrollY
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className={`appBar${!isOpen ? (showAppBar ? ' visible' : ' hidden') : ''}`}>
      <div className={'menuIcon'} onClick={() => setIsOpen(true)}>
        <img src={menu} />
      </div>
      <div className={'logoContainer'}>
        <LinkS to={'Home'} spy={true} smooth={true}>
          <img src={logo} alt='' />
        </LinkS>
      </div>
      {isOpen && (
        <div className='sideBarContainer'>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className={'sideBarLogo'}>
              <img src={logo} />
            </div>
            <IconButton onClick={sideBarHandler}>
              <img src={close} />
            </IconButton>
          </div>
          <div className={'menu'}>
            {menuItems.map((item) => {
              return (
                <LinkS
                  className={`${item === active ? 'active' : 'inactive'} sidebar`}
                  to={item}
                  spy={true}
                  smooth={true}
                  offset={36}
                  onClick={handleSetActive}
                  key={item}
                >
                  {item}
                </LinkS>
              )
            })}
          </div>
          <div className='side-btns'>
            <Button variant='contained' href='reservations' fullWidth>
              Instant Quote
            </Button>
            <Button variant='outlined' href='https://calendly.com/mo-mixingmos/phone-consultation' target='_blank' fullWidth>
              Schedule A Call
            </Button>
            <SocialMediaIcons />
          </div>
        </div>
      )}

      <div className={'menuContainer'}>
        <div className='extendedMenu'>
          {menuItems.map((item) => {
            return (
              <LinkS
                className={item === active ? 'active' : 'inactive'}
                to={item}
                spy={true}
                smooth={true}
                offset={48}
                onSetActive={handleSetActive}
                key={item}
              >
                {item}
              </LinkS>
            )
          })}
        </div>
        <div className='app-bar-btns'>
          <Button variant='contained' href='reservations'>
            {' '}
            Instant Quote
          </Button>
          <Button variant='outlined' href='https://calendly.com/mo-mixingmos/phone-consultation' target='_blank'>
            Schedule A Call
          </Button>
          <SocialMediaIcons />
        </div>
      </div>
    </div>
  )
}

export default AppBar
