import { Button, Divider, TextField } from '@mui/material'
import SectionHeader from './pages/Booking/SectionHeader'
import person from './resources/icons/person.svg'
import { useOutletContext } from 'react-router-dom'
import { useState } from 'react'
import './ContactInfo.css'
import axios from 'axios'
import Info from './Messages/Info'

const ContactInfo = () => {
  const { first, setFirst, last, setLast, email, setEmail, phone, setPhone, handleNextPage, setCustomer, customer } = useOutletContext()
  const [validEmail, setValidEmail] = useState(false)
  const [validPhone, setValidPhone] = useState(false)
  const [error, setError] = useState([])
  const [notification, setNotification] = useState(null)

  const handleEmail = (e) => {
    setEmail(e.target.value)
    if (e.target.value.includes('@')) {
      setValidEmail(true)
    } else {
      setValidEmail(false)
    }
  }

  const handlePhone = (e) => {
    const phoneInput = e.target.value
    setPhone(phoneInput)
    if (/^\d+$/.test(phoneInput)) {
      setPhone(phoneInput.slice(0, 10))
    } else if (phoneInput === '') {
      setPhone(phoneInput)
    }

    if (phoneInput.length < 9) {
      setValidPhone(false)
    } else {
      setValidPhone(true)
    }
  }
  const createCustomer = async () => {
    if (!customer) {
      try {
        const customerResponse = await axios.post(`${process.env.REACT_APP_API_URL}/customer`, {
          givenName: first,
          familyName: last,
          emailAddress: email,
          phoneNumber: phone
        })
        setCustomer(customerResponse.data.customer)
        setNotification(null)
        setValidEmail(true)
        setValidPhone(true)
        handleNextPage()
      } catch (error) {
        console.error('Error creating user...', error)
        const errors = error.response.data.errors
        const notifications = errors.map((error) => {
          if (error.code === 'INVALID_PHONE_NUMBER') {
            setValidPhone(false)
          } else if (error.code === 'INVALID_EMAIL_ADDRESS') {
            setValidEmail(false)
          } else {
            throw error
          }
          return {
            title: error.code,
            message: error.detail,
            type: 'error'
          }
        })
        const n = {
          title: 'Invalid email or phone number',
          message: 'Please provide a valid information and try again.',
          type: 'error'
        }
        setError(notifications)
        setNotification(n)
      }
    } else {
      console.log('User already created..')
      handleNextPage()
    }
  }

  return (
    <div className='form-container'>
      <SectionHeader icon={person} instruction={'Tell us a little bit about yourself'} />
      <Divider />
      <span className='header-description'>Please provide the personal details for the event&apos;s point of contact.</span>
      <div className='personal-details'>
        <TextField required placeholder='First Name' label='First Name' value={first} onChange={(e) => setFirst(e.target.value)} />
        <TextField required placeholder='Last Name' label='Last Name' value={last} onChange={(e) => setLast(e.target.value)} />
        <TextField
          required
          placeholder='Email Address'
          label='Email Address'
          value={email}
          onChange={handleEmail}
          type='email'
          helperText={!email.includes('@') && email && 'Enter a valid email.'}
          error={!validEmail && email !== ''}
          // error={notification}
        />
        <TextField
          required
          placeholder='Phone Number'
          label='Phone Number'
          value={phone}
          onChange={handlePhone}
          type='tel'
          helperText={phone.length < 9 && phone ? 'Enter a valid phone number' : ''}
          error={!validPhone && phone !== ''}
          // error={notification}
        />
      </div>

      {notification && (
        <div className='info-div' key={'error'}>
          <Info notification={notification} />
        </div>
      )}

      <Button disabled={!first || !last || !phone || !email} onClick={createCustomer} variant='contained'>
        {error.length > 0 ? 'Retry' : 'Continue'}
      </Button>
    </div>
  )
}

export default ContactInfo
