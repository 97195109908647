import { packages } from './data'
import { Chip } from '@mui/material'
import './AvailableServices.css'
import PackageTile from './PackageTile'
import { useState } from 'react'

const AvailableServices = () => {
  const [service, setService] = useState(packages[0])

  return (
    <div id='Services' className='services-container'>
      <div className='services-header'>
        <div className='header-section'>
          <span className='heading'>What can we do for you?</span>
          <span className='stock-description'>We&apos;ve ditched the pre-built packages so that you only pay for what you need.</span>
        </div>
        <div className='service-chips-div'>
          <Chip
            size='large'
            label='My guests will serve themselves.'
            clickable
            onClick={() => {
              setService(packages.find((p) => p.title === 'Self-Serve'))
            }}
            color={service.title === 'Self-Serve' ? 'primary' : 'default'}
            sx={{
              fontSize: '18px'
            }}
          />
          <Chip
            size='large'
            label='I have a bar; I only need bartenders.'
            onClick={() => {
              setService(packages.find((p) => p.title === 'Just Pour'))
            }}
            color={service.title === 'Just Pour' ? 'primary' : 'default'}
            sx={{
              fontSize: '18px'
            }}
          />
          <Chip
            size='large'
            label='I need to rent a portable bar.'
            onClick={() => {
              setService(packages.find((p) => p.title === 'Bring the Bar'))
            }}
            color={service.title === 'Bring the Bar' ? 'primary' : 'default'}
            sx={{
              fontSize: '18px'
            }}
          />
        </div>
      </div>
      <div className='all-packages-container' id='Services'>
        {packages.map((p, idx) => {
          return (
            <div key={'package' + idx}>
              <PackageTile servicePackage={p} selected={service.title === p.title} setService={setService} redirect={true} />
            </div>
          )
        })}
      </div>
      <div className='packages-container'>
        <PackageTile servicePackage={service} selected={true} setService={setService} redirect={true} />
      </div>
    </div>
  )
}

export default AvailableServices
