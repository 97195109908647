import SectionHeader from '../pages/Booking/SectionHeader'
import guests from '../resources/icons/guests.svg'
import './Guests.css'
import { useOutletContext } from 'react-router-dom'
import { useState } from 'react'
import { Button, Divider, IconButton } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

const Guests = () => {
  const { guestCount, setGuestCount, handleNextPage } = useOutletContext()
  const [error, setError] = useState('')

  const handleAdd = () => {
    const num = parseInt(guestCount)
    if (num + 10 <= 200) {
      setGuestCount(num + 10)
      setError('')
    }
  }

  const handleSubtract = () => {
    const num = parseInt(guestCount)
    if (num - 10 >= 50) {
      setError('')
      setGuestCount(guestCount - 10)
    }
  }

  return (
    <div className='form-container'>
      <SectionHeader icon={guests} instruction={'Anticipated guest count:*'} />
      <Divider />
      <span className='header-description'>Reservation requires a minimum of 50 guests and cannot exceed 200.</span>

      <div className='count-controls'>
        <IconButton className='minus' onClick={handleSubtract} disabled={guestCount === 50} color='primary'>
          <RemoveCircleIcon />
        </IconButton>
        <span className='heading3'>{guestCount}</span>

        <IconButton className='plus' onClick={handleAdd} disabled={guestCount === 200} color='primary'>
          <AddCircleIcon />
        </IconButton>
      </div>
      <div className='form-btn-group'>
        {error && <span className='error-caption'>{error}</span>}
        <Button variant='contained' fullWidth onClick={handleNextPage} disabled={guestCount < 50 || guestCount > 200}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default Guests
0
