import { useEffect, useState } from 'react'
import check from '../resources/icons/check.svg'
import { Divider, Select, Button, MenuItem } from '@mui/material'
import './PackageTile.css'
import { useOutletContext } from 'react-router-dom'

const PackageTile = ({ servicePackage, selected, setService }) => {
  const [activeMenu, setActiveMenu] = useState(servicePackage.menus[0])
  const [value, setValue] = useState(servicePackage.menus[0].name)
  const context = useOutletContext()

  useEffect(() => {
    setActiveMenu(servicePackage.menus[0])
    setValue(servicePackage.menus[0].name)
  }, [servicePackage])

  const handleChange = (e) => {
    setValue(e.target.value)
    setService(servicePackage)
    setActiveMenu(servicePackage.menus.find((menu) => menu.name === e.target.value))
  }

  const handleClick = () => {
    if (context) {
      context.handleNextPage()
    } else {
      window.location.replace(`reservations/service/${servicePackage.title}/${activeMenu.name}`)
    }
  }

  // useEffect(() => {
  //   // console.log(activeMenu)
  //   console.log(value)
  //   // console.log(servicePackage.menus[0])
  // }, [])
  return (
    <div className={`package ${selected ? 'selected' : ''}`} onClick={() => setService(servicePackage)}>
      <div className='package-img'>
        <img src={servicePackage.img} alt='service-img' />
      </div>
      <span className='heading'>{servicePackage.title}</span>
      <span>{servicePackage.description}</span>
      <div className='whats-included-container'>
        {servicePackage.included.map((inclusion, idx) => {
          return (
            <div key={'inclusion' + idx} className='package-inclusion'>
              <div className='iconContainer'>
                <img src={check} />
              </div>
              <span>{inclusion}</span>
            </div>
          )
        })}
      </div>
      <div>
        <Divider />
        <div className='menus-container'>
          <span className='heading3'>Available Menus</span>
          <span className='caption'>
            Note: Offering more drink options increases labor costs for bar staff and ingredients. Additionally, more bar equipment may be
            required.
          </span>
          <Select value={value} onChange={handleChange} color='primary' disabled={!selected}>
            {servicePackage.menus.map((menu, idx) => {
              return (
                <MenuItem
                  key={servicePackage.title + '-' + menu.name + '-' + idx}
                  value={menu.name}
                  sx={{ fontFamily: 'Cabin Condensed', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <span className='menu-view'>{menu.name}</span>
                  <div className='whats-included-container'>
                    {menu.options.map((option, idx) => {
                      return (
                        <div
                          key={servicePackage.title.toLocaleLowerCase().trim() + activeMenu.name.toLocaleLowerCase() + '-option' + idx}
                          className='options-div'
                        >
                          {/* <div className='iconContainer'>
                                <img src={check} />
                              </div> */}
                          <span>{option.num + ' ' + option.type}(s)</span>
                        </div>
                      )
                    })}
                  </div>
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <Divider />
        <div className='menus-container'>
          <span className='heading3'>Required to Book</span>
          <div className='whats-included-container'>
            {servicePackage.requirements?.map((requirement, idx) => {
              return (
                <div key={servicePackage.title.toLocaleLowerCase().trim() + '-requirement' + idx} className='package-inclusion'>
                  <div className='iconContainer'>
                    <img src={check} />
                  </div>
                  <span>{requirement}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Button variant='contained' fullWidth onClick={handleClick} disabled={!selected}>
        Personalize Package
      </Button>
    </div>
  )
}

export default PackageTile
