import { Button } from '@mui/material'
import heroBg from '../resources/stock/hero-bg.png'
import AvailibilityForm from './AvailabilityForm.js/AvailabilityForm'

import './HeroSection.css'
import { Link } from 'react-scroll'

const HeroSection = () => {
  return (
    <div id='Home' className='hero-container'>
      <div className='hero-content'>
        <div className='imgWrapper'>
          <img src={heroBg} />
        </div>
        <div className='hero-header'>
          <span className='hero-title'>The bar that comes to you</span>
          <span className='hero-subtext'> &#8212; Check availability, personalize service, and reserve in less than 10 minutes.</span>
        </div>
        <div className='hero-btn-group'>
          <Button variant='contained' size='large' href='reservations'>
            Get Started
          </Button>
          <Link to='How it Works' smooth offset={48}>
            <Button size='large'>How it Works</Button>
          </Link>
        </div>
      </div>

      <AvailibilityForm />
    </div>
  )
}

export default HeroSection
