import dayjs from 'dayjs'
import './Ratings.css'
import { Button, Drawer, IconButton, Rating, Slide, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ConfettiExplosion from 'react-confetti-explosion'
import closeIcon from '../resources/icons/close.svg'

const Ratings = () => {
  const [description, setDescription] = useState('')
  const [rating, setRating] = useState(0)
  const [isReviewing, setIsReviewing] = useState(false)
  const [reviews, setReviews] = useState([])
  const [name, setName] = useState('')
  const [success, setSuccess] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [show, setShow] = useState(true)
  const [averageRating, setAverageRating] = useState(0)
  const [viewAll, setViewAll] = useState(false)

  const handleDescription = (e) => {
    setDescription(e.target.value)
    setIsReviewing(true)
  }

  const getReviews = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/reviews`)
    const foundReviews = response.data.map((review, idx) => {
      return (
        <div className='review-tile' key={'review' + idx}>
          <div className='rating-div'>
            <Rating value={review.rating} readOnly size='large' />
            <span className='caption'>{dayjs(review.date).format('MMMM DD, YYYY')}</span>
          </div>

          <span className='review-description'>&quot;{review.description}&quot;</span>
          <div className='rating-details'>
            <span className='heading3'>&#8212; {review.name}</span>
          </div>
        </div>
      )
    })
    setReviews(foundReviews)
    let total = 0
    response.data.map((review) => {
      total += review.rating
    })
    setAverageRating(total / response.data.length)
  }
  useEffect(() => {
    getReviews()
  }, [])

  const saveRating = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/reviews`, {
      name,
      rating,
      description,
      date: dayjs()
    })
    await getReviews()
  }

  const reset = () => {
    setName('')
    setRating(0)
    setDescription('')
    setIsReviewing(false)
  }

  const handleSave = async () => {
    await saveRating()
    setSuccess(true)
    reset()
  }

  const handleNext = () => {
    setShow(false)
    setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % reviews.length)
      setShow(true)
    }, 300) // Delay to synchronize with Slide animation
  }

  const handlePrev = () => {
    setShow(false)
    setTimeout(() => {
      setCurrentIndex((prev) => (prev - 1 + reviews.length) % reviews.length)
      setShow(true)
    }, 300)
  }

  return (
    <div className='ratings-container'>
      {!success ? (
        <div className='ratings-header'>
          <span className='heading'>How was your service?</span>
          <span className='header-subtext'>Please take a moment to rate and review.</span>
          <Rating max={5} value={rating} size='medium' onChange={(e) => setRating(e.target.value)} />
          <TextField
            placeholder='Begin typing your review..'
            fullWidth
            onChange={handleDescription}
            value={description}
            required={isReviewing}
            multiline
            minRows={8}
          />
          {isReviewing && (
            <>
              <TextField
                placeholder='Enter your full name'
                required
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
              <Button variant='contained' fullWidth onClick={handleSave}>
                Submit Review
              </Button>
            </>
          )}
        </div>
      ) : (
        <div className='section-header'>
          <ConfettiExplosion particleCount={75} colors={['#79ac78', '#cba163', '#618264']} />
          <span className='heading'>Thank you</span>
          <span className='header-subtext'>
            Thank you so much for taking the time to leave a review! Your feedback means a lot to us and helps us continue to improve our
            service.
          </span>
        </div>
      )}
      {reviews.length > 0 ? (
        <div className='reviews'>
          <div className='overall-rating'>
            <Rating max={1} value={1} readOnly size='large' />
            <span className='heading3'>
              {averageRating.toFixed(1)} <a onClick={() => setViewAll(true)}>({reviews.length} Reviews)</a>
            </span>
          </div>
          <div className='reviews-carasoul'>
            <>
              <Stack spacing={2} alignItems='center' className='stack-container'>
                <Slide direction='left' in={show} mountOnEnter unmountOnExit>
                  {reviews[currentIndex] ? reviews[currentIndex] : <div>No review available</div>}
                </Slide>
              </Stack>
              <Stack direction='row' spacing={2} className='review-btns'>
                <Button variant='contained' onClick={handlePrev} disabled={currentIndex === 0}>
                  Previous
                </Button>
                <Button variant='contained' onClick={handleNext} disabled={currentIndex === reviews.length - 1}>
                  Next
                </Button>
              </Stack>
            </>
          </div>
        </div>
      ) : (
        <div>
          <span>Be the first to leave a review. </span>
        </div>
      )}
      <Drawer open={viewAll} className='reviews-list' anchor={'bottom'}>
        <div className='drawer-header'>
          <div>
            <span className='heading'>{reviews.length} Reviews</span>
            <div className='overall-rating'>
              <Rating max={1} value={1} readOnly size='large' />
              <span className='heading3'>{averageRating.toFixed(1)}</span>
            </div>
          </div>
          <IconButton onClick={() => setViewAll(!viewAll)}>
            <img src={closeIcon} alt='close-icon' />
          </IconButton>
        </div>

        <div className='list-content'>{reviews?.map((review) => review)}</div>
      </Drawer>
      {/* <span>Don&apos;t just take our word for it.</span> */}
    </div>
  )
}

export default Ratings
