import { useEffect, useState } from 'react'
import { Stack, Slide, Box, Button } from '@mui/material'
import AddressValidation from '../AddressValidation/AddressValidation'
import EventDate from '../EventDate/EventDate'
import EventDuration from '../EventDuration/EventDuration'
import Guests from '../Guests/Guests'
import Service from '../Service/Service'
import { useOutletContext } from 'react-router-dom'
import './BookingCarousol.css'
import ReservationReview from '../Reservation/ReservationReview'
import ContactInfo from '../ContactInfo'

const BookingCarousol = () => {
  const context = useOutletContext()
  const [cards, setCards] = useState([])
  const cardsPerPage = 1

  const duplicateCards = [
    <AddressValidation key={0} />,
    <EventDate key={1000} />,
    <EventDuration key={3} context={useOutletContext()} />,
    <Guests key={5} />,
    <Service key={6} />,
    <ContactInfo key={99} />,
    <ReservationReview key={8} />
  ]

  useEffect(() => {
    setCards(duplicateCards)
  }, [])
  return (
    <div className='advanced-booking'>
      <Box
        sx={{
          color: 'var(--text)',
          display: 'flex',
          flexDirection: 'row',
          width: '100%'
        }}
      >
        <Box
          className='form-container-parent'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
            padding: '0 3rem',
            marginBottom: '2rem',
            gap: '2rem'
          }}
        >
          {cards.map((card, idx) => {
            return (
              <Box
                className='form-container-div'
                key={`card-${idx}`}
                style={{ flex: '1' }}
                sx={{ width: '100%', display: context.currentPage === idx ? 'block' : 'none' }}
              >
                <Slide direction={context.slideDirection} in={context.currentPage === idx}>
                  <Stack spacing={2} direction='row' alignContent='center' justifyContent='center' sx={{ display: 'flex' }}>
                    {cards.slice(idx * cardsPerPage, idx * cardsPerPage + cardsPerPage)}
                  </Stack>
                </Slide>
                <div className='need-help'>
                  <Button variant='outlined' href='mailto:mo@mixingmos.com'>
                    Need help?
                  </Button>
                </div>
              </Box>
            )
          })}
        </Box>
      </Box>
    </div>
  )
}

export default BookingCarousol
