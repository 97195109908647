import './Info.css'
import infoIcon from '../resources/icons/info.svg'
import errorIcon from '../resources/icons/error.svg'
import successIcon from '../resources/icons/success.svg'

const Info = ({ notification }) => {
  let icon
  if (notification.type === 'error') {
    icon = errorIcon
  } else if (notification.type === 'success') {
    icon = successIcon
  } else {
    icon = infoIcon
  }
  return (
    <div className={`info-message ${notification.type}`}>
      <img src={icon} alt='info-icon' />
      <div className='info-message-description'>
        <span className='info-message-title'>{notification.title}</span>
        <div>{notification.message}</div>
      </div>
    </div>
  )
}

export default Info
