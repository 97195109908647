import selfServe from '../resources/stock/self-serve.png'
import justPour from '../resources/stock/just-pour.png'
import bringTheBar from '../resources/stock/bring-the-bar.png'

const standardMenus = [
  {
    name: 'Wine and Beer',
    options: [
      { type: 'Wine', num: 3 },
      { type: 'Beer', num: 3 }
    ],
    multiplier: 0.2
  },
  {
    name: 'Modified',
    options: [
      { type: 'Wine', num: 2 },
      { type: 'Beer', num: 2 },
      { type: 'Spirit', num: 3 },
      { type: 'Signature cocktail recipe', num: 1 }
    ],
    multiplier: 0.3
  },
  {
    name: 'Full',
    options: [
      { type: 'Wine', num: 3 },
      { type: 'Beer', num: 3 },
      { type: 'Spirit', num: 6 },
      { type: 'Signature cocktail recipe', num: 2 }
    ],
    multiplier: 0.45
  }
]

export const packages = [
  {
    img: selfServe,
    title: 'Self-Serve',
    description: 'A sophisticated and visually appealing self-service station for guests to help themselves to champagne or cocktails.',
    requirements: ['Designated 4ft (L) x 3ft (W) of space'],
    included: [
      'Travel',
      'Set up',
      'Table(s) and cover',
      'Small decor items and signage ( Up $50 value)',
      'Bar supplies (cups, straws, ice, etc.)',
      'Menu Consultation'
    ],
    menus: [
      {
        name: 'Champagne',
        options: [{ type: 'Wine', num: 1 }],
        included: [
          { product: '4ft Foldable Table (cover and decor included)', qty: 1, serves: 50 },
          { product: '9oz Stemless Plastic Champagne Flutes (48pk)', qty: 1, serves: 48 },
          { product: 'Ice Bins (2pk)', qty: 1, serves: 18 }
        ],
        multiplier: 0.1
      },

      // {
      //   name: 'Mimosa',
      //   options: [
      //     { type: 'Wine', num: 1 },
      //     { type: 'Traditional, Cranberry, Grapefruit, or Flight (combined) flavors', num: 1 }
      //   ],
      //   included: [
      //     { product: '4ft Foldable Table (cover and decor included)', qty: 1, serves: 50 },
      //     { product: '9oz Stemless Plastic Champagne Flutes (48pk)', qty: 1, serves: 48 },
      //     { product: 'Ice Bins (2pk)', qty: 1, serves: 18 },
      //     { product: 'Plastic Drink Carafes (3pk)', qty: 1, serves: 18 }
      //   ],
      //   multiplier: 0.1
      // },
      {
        name: 'Signature Cocktail Recipe',

        options: [{ type: 'Signature Cocktail Recipe', num: 1 }],
        included: [
          { product: '4ft Foldable Table (cover and decor included)', qty: 1, serves: 50 },
          { product: ' 3gal Beverage Dispenser', qty: 1, serves: 50 },
          { product: ' 3qt Ice Bucket w/ Lid and Scoop', qty: 1, serves: 25 }
        ],
        multiplier: 0.15
      }
    ],
    pricing: {
      distance: {
        standard: 60,
        long: 282
      },
      hourly: [
        { type: 'bartender', amt: 33.33 },
        { type: 'security', amt: 25 },
        { type: 'loader', amt: 15 }
      ],
      cogsMultiplier: 2
    }
  },
  {
    img: justPour,
    title: 'Just Pour',
    description:
      'Delivering bartending services only, we strive to exceed expectations and elevate the overall beverage experience for our clients. Must have bar available at venue. ',
    requirements: ['Functional bar with sink. *Photo may be requested prior to event fulfillment.'],
    included: [
      '2 bartenders',
      'Bar utensils (shakers, pourers, strainer, etc.)',
      'Bar supplies (cups, straws, ice, etc.)',
      'Assorted syrups and fruit garnish',
      'Well mixers (cranberry, coke, sprite, etc.)',
      'Menu Consultation',
      'Personalized menu (printed)',
      'Mobile shopping list'
    ],
    menus: standardMenus,
    pricing: {
      distance: {
        standard: 120,
        long: 470
      },
      hourly: [
        { type: 'bartender', amt: 41.67 },
        { type: 'barback', amt: 30 },
        { type: 'security', amt: 25 },
        { type: 'loader', amt: 25 }
      ],
      cogsMultiplier: 4
    }
  },
  {
    img: bringTheBar,
    title: 'Bring the Bar',
    description:
      'In addition to our bartending staff, we’re offering acrylic bar rentals to enhance your event with a touch of modern elegance and versatility.',
    requirements: [
      'Designated area - 7ft (L) x 7ft (W)',
      '2 outlets for power supply',
      'Ample space for trailer parking with easy access for loading and unloading dollies and rolling equipment'
    ],
    included: [
      'Multicolor LED Acrylic Bar Rental',
      '2 bartenders',
      'Bar utensils (shakers, pourers, strainer, etc.)',
      'Bar supplies (cups, straws, ice, etc.)',
      'Assorted syrups and fruit garnish',
      'Well mixers (cranberry, coke, sprite, etc.)',
      'Menu Consultation',
      'Personalized menu (printed)',
      'Mobile shopping list'
    ],
    menus: standardMenus,
    pricing: {
      distance: {
        standard: 195,
        long: 545
      },
      hourly: [
        { type: 'bartender', amt: 66.67 },
        { type: 'barback', amt: 43.33 },
        { type: 'security', amt: 25 },
        { type: 'loader', amt: 40 }
      ],
      cogsMultiplier: 4,
      barRental: 850
    }
  }
]
