import './SectionHeader.css'
const SectionHeader = ({ icon, instruction }) => {
  return (
    <div className='section-header-container'>
      <div className='header-icon'>
        <img src={icon} />
      </div>
      <span className='heading3'>{instruction}</span>
    </div>
  )
}

export default SectionHeader
