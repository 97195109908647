import { Button, Divider } from '@mui/material'
import EventDetails from '../EventDetails/EventDetails'
import './Dashboard.css'
import MailIcon from '@mui/icons-material/Mail'
import { useParams } from 'react-router-dom'
import Policy from '../Policy/Policy'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Subtotal from '../CostEstimate/Subtotal'
import logo from '../resources/logo/logo.svg'

const Dashboard = () => {
  const { id } = useParams()
  const [reservation, setReservation] = useState(null)
  const [order, setOrder] = useState(null)

  function formatMoney(number) {
    // Divide by 100 to get the correct decimal place
    const amount = number / 100

    // Format the number to a string with commas and two decimal places
    return amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  useEffect(() => {
    async function getReservation() {
      const reservationResponse = await axios.get(`${process.env.REACT_APP_API_URL}/reservation/${id}`)
      const r = reservationResponse.data
      console.log(r)
      setReservation(r)
    }
    getReservation()
  }, [])

  useEffect(() => {
    async function getOrder() {
      const orderResponse = await axios.get(`${process.env.REACT_APP_API_URL}/order/${reservation?.orderId}`)
      setOrder(orderResponse.data.order)
    }
    if (reservation?.orderId) getOrder()
  }, [reservation])
  const props = {
    isPaying: false,
    eventDate: reservation?.eventDate,
    paymentMethod: reservation?.paymentMethod,
    order: order,
    confirmed: true
  }

  const eventProps = {
    eventAddress: reservation?.eventAddress,
    eventDate: reservation?.eventDate,
    guestCount: reservation?.guestCount,
    eventStart: reservation?.eventStart,
    eventEnd: reservation?.eventEnd
  }

  return (
    <div className='form-container-green'>
      <div className='dashboard-detail'>
        <Button fullWidth sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }} href='/'>
          <div className='logo-div-confirmed'>
            <img src={logo} />
          </div>
        </Button>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className='body1'> {dayjs(reservation?.eventDate).diff(dayjs(), 'day')} days until your event</span>
          <span className='heading'>Hi, {reservation?.first}!</span>
        </div>
        <div className='detail-div'>
          <EventDetails {...eventProps} />
        </div>
        <Divider />
        <span className='heading4'>Contact</span>
        <div className='personal-info'>
          <b>
            <span>{`${reservation?.first} ${reservation?.last}`}</span>
          </b>
          <span>{reservation?.email}</span>
          <span>{reservation?.phone}</span>
        </div>
        <div className='dash-btn-group'>
          {/* <Button variant='outlined' startIcon={<DescriptionIcon />}>
            Sign Agreement
          </Button> */}
          <Button variant='outlined' startIcon={<MailIcon />} href='mailto:mo@mixingmos.com'>
            Contact Us
          </Button>
        </div>
      </div>
      <div className='dashboard-detail'>
        <span className='heading3'>Reservation Details</span>
        <div className='amount-due'>
          <Subtotal {...props} />
        </div>
      </div>

      <div className='dashboard-detail'>
        <span className='heading3'>Payments</span>
        <div className='payments-table'>
          <div className='payments-row'>
            <span className='col-left' id='heading'>
              Payment
            </span>
            <span className='col-left' id='heading'>
              Paid On
            </span>
            <span id='heading'>Amount</span>
          </div>
          <Divider />
          {reservation?.payments?.map((payment, idx) => {
            return (
              <div className='payments-row' key={'payment' + idx}>
                <span className='col-left'>{payment.description}</span>
                <span className='col-left'>{dayjs(payment.date).format('MM/DD/YYYY')}</span>
                <span className='col'>${formatMoney(Number(payment.amount))}</span>
              </div>
            )
          })}
        </div>
      </div>
      <div className='dashboard-detail'>
        <span className='heading3'>Policies</span>
        <Policy />
      </div>
    </div>
  )
}

export default Dashboard
