import SectionHeader from '../pages/Booking/SectionHeader'
import bar from '../resources/icons/bar.svg'
import { packages } from '../Services/data'
import { useOutletContext, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import PackageTile from '../Services/PackageTile'
import './Service.css'
import { Divider } from '@mui/material'

const Service = () => {
  const { service, setService, setMenu } = useOutletContext()
  const { serviceName, menuName } = useParams()
  useEffect(() => {
    if (serviceName && menuName) {
      const serviceObject = packages.find((p) => p.title === serviceName)
      setService(serviceObject)
      const menuObject = serviceObject.menus.find((m) => m.name === menuName)
      if (menuObject) {
        setMenu(menuObject)
      }
    }
  }, [])
  return (
    <div className='form-container'>
      <SectionHeader icon={bar} instruction={'Select your service and menu:'} />
      <Divider />
      <span className='header-description'>
        Decide on the <b>type</b> of menu your bar will offer. You will have the opportunity to choose your products after confirming your
        reservation.
      </span>
      <div className='service-selection-div'>
        {packages.map((p, idx) => {
          return <PackageTile key={p.title + idx} servicePackage={p} selected={service.title === p.title} setService={setService} />
        })}
      </div>
    </div>
  )
}

export default Service
