import SectionHeader from '../pages/Booking/SectionHeader'
import calendar from '../resources/icons/calendar.svg'
import './EventDate.css'
import { useOutletContext } from 'react-router-dom'
import { Button, Divider } from '@mui/material'
import 'react-calendar/dist/Calendar.css'
import AvailabilityCalendar from '../HeroSection/AvailabilityForm.js/AvailabilityCalendar'

const EventDate = () => {
  const context = useOutletContext()

  return (
    <div className='form-container'>
      <SectionHeader icon={calendar} instruction={'When is the big day?*'} />
      <Divider />
      <span className='header-description'>Reservations must be made at least fourteen days in advance.</span>
      <AvailabilityCalendar setEventDate={context.setEventDate} />
      <div className='form-btn-group'>
        <Button variant='contained' fullWidth onClick={context.handleNextPage} disabled={!context.eventDate}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default EventDate
