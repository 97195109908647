import './AvailabilityForm.css'
import { Button, Divider } from '@mui/material'
import GoogleAddressInput from '../../GoogleAddressInput'
import { useState } from 'react'
import AvailabilityCalendar from './AvailabilityCalendar'
import Map from '../../Drawers/Map'

const AvailibilityForm = () => {
  const [eventAddress, setEventAddress] = useState('')
  const [distance, setDistance] = useState(null)
  const [eventDate, setEventDate] = useState('')
  const [open, setOpen] = useState(false)
  return (
    <div className='form'>
      <div className='form-div'>
        <div className='form-header'>
          <div className='ga-based'>
            <div className={'ga-content'}>
              <span style={{ fontSize: '2.5rem', fontWeight: 'bold', color: 'var(--primary-light)' }}>Check Availability</span>
              <div>
                <span style={{ fontSize: '1.5rem', color: 'var(--primary-light)' }}>Providing services from Middle Georgia to Atlanta</span>
              </div>
            </div>
          </div>
          <Map open={open} setOpen={setOpen} />
        </div>
        <Divider />
        <div className='form-inputs-div'>
          <div className='form-inputs'>
            <div className='form-section'>
              {' '}
              <span className='heading3'> Select from our available reservation dates:</span>
              <AvailabilityCalendar setEventDate={setEventDate} />
            </div>
            {eventDate && (
              <div className='form-section'>
                <span className='heading3'>
                  Enter the full addresss of your venue. Cities, zip codes, and addresses outside the state of Georgia not accepted.
                </span>
                <GoogleAddressInput
                  eventAddress={eventAddress}
                  setEventAddress={setEventAddress}
                  distance={distance}
                  setDistance={setDistance}
                />
              </div>
            )}
          </div>
          <Button variant='contained' disabled={!eventDate || !eventAddress} href={`reservations/form/${eventDate}/${eventAddress}`}>
            Continue Reservation
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AvailibilityForm
