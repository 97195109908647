import { Button, Divider, Drawer } from '@mui/material'
import './EstimateDrawer.css'
import Subtotal from './Subtotal'
import { useOutletContext } from 'react-router-dom'
/* TO DO:
- how is my quote calculated
- add taxes and service charges
- create excel sheet to testing calculations
 */
const EstimateDrawer = ({ open, setOpen, setConfirming, paymentRef }) => {
  const { eventDate, paymentMethod, netDue, lineItems, order } = useOutletContext()
  const scrollToPay = () => {
    if (paymentRef.current) {
      paymentRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }
  const handlePaymentOptions = () => {
    setOpen(!open)
    setConfirming(true)
    scrollToPay()
  }

  const props = {
    isPaying: false,
    eventDate,
    paymentMethod,
    netDue,
    lineItems,
    order
  }

  return (
    <div style={{ position: 'relative', width: '300px' }}>
      <Drawer
        anchor='bottom'
        open={true}
        sx={{
          padding: '2rem'
        }}
      >
        <div className='est-drawer'>
          <div className='estimate-header'>
            <span className='heading'>Your cost estimate</span>
            <span>Based on the information provided, Mixing Mo&apos;s has generated the following cost estimate. </span>
          </div>
          <Divider />
          <Subtotal {...props} />
          <div className='estimate-btn-grp'>
            <Button variant='contained' fullWidth onClick={handlePaymentOptions}>
              Continue Booking
            </Button>
          </div>
          <div></div>
        </div>
      </Drawer>
    </div>
  )
}

export default EstimateDrawer
