import { useEffect, useState, useRef } from 'react'
import { Button, TextField } from '@mui/material'
import { useOutletContext } from 'react-router-dom'
import './ReservationReview.css'
import check from '../resources/icons/check.svg'
import EventDetails from '../EventDetails/EventDetails'
import EstimateDrawer from '../CostEstimate/EstimateDrawer'
import axios from 'axios'
import Payment from '../PaymentOptions/Payment'

const ReservationReview = () => {
  const {
    service,
    menu,
    first,
    last,
    email,
    phone,
    distance,
    locationRadio,
    eventEnd,
    eventStart,
    guestCount,
    setNetDue,
    setLineItems,
    eventAddress,
    eventDate,
    comments,
    setComments,
    setOrder
  } = useOutletContext()
  const [estimateOpen, setEstimateOpen] = useState(false)
  const [getQuote, setGetQuote] = useState(false)
  const [confirming, setConfirming] = useState(false)
  const paymentRef = useRef(null)

  const convertToBasePrice = (amt) => {
    return Math.round(amt * 100)
  }

  const eventProps = {
    eventAddress,
    eventDate,
    guestCount,
    eventStart,
    eventEnd
  }

  useEffect(() => {
    async function calculateOrder() {
      // distance
      const calculatedLineItems = []
      const standardTravel = distance < 48280.3
      const travelLineItem = {
        name: standardTravel ? 'Standard Travel (Up to 30 miles)' : 'Long Distance Travel (More than 30 miles)',
        quantity: '1',
        basePriceMoney: {
          amount: standardTravel
            ? convertToBasePrice(service.pricing.distance.standard)
            : convertToBasePrice(service.pricing.distance.long),
          currency: 'USD'
        }
      }
      calculatedLineItems.push(travelLineItem)

      if (locationRadio) {
        calculatedLineItems.push({
          name: 'Outdoor Equipment Rental',
          quantity: '1',
          basePriceMoney: {
            amount: convertToBasePrice(120),
            currency: 'USD'
          }
        })
      }

      //calculate service
      let totalHourlys = 0
      let surcharge = 0
      const duration = eventEnd.diff(eventStart, 'hour')
      const hourlySums = service.pricing.hourly.map((rate) => {
        let multSum = 0
        if (locationRadio === 'outdoor') {
          multSum += 0.1
        }
        if (rate.type === 'bartender' || rate.type === 'barback') {
          multSum += menu.multiplier
          surcharge = (rate.amt * 0.25 + rate.amt) * (duration - 3)
        }
        return (rate.amt * multSum + rate.amt) * 3
      })
      hourlySums.forEach((sum) => {
        totalHourlys += sum
      })
      const cogs = parseInt(guestCount) * service.pricing.cogsMultiplier
      const totalService = cogs + totalHourlys
      const serviceProfit = totalService * 0.3 + totalService

      calculatedLineItems.push({
        name: 'Service',
        variationName: `${service.title} (${menu.name})`,
        quantity: '1',
        basePriceMoney: {
          amount: convertToBasePrice(serviceProfit),
          currency: 'USD'
        }
      })

      if (surcharge > 0) {
        calculatedLineItems.push({
          name: 'Extended Service',
          quantity: '1',
          basePriceMoney: {
            amount: convertToBasePrice(surcharge),
            currency: 'USD'
          }
        })
      }

      if (service.pricing.barRental) {
        calculatedLineItems.push({
          name: 'Acrylic Bar Rental',
          quantity: '1',
          basePriceMoney: {
            amount: convertToBasePrice(service.pricing.barRental),
            currency: 'USD'
          }
        })
      }
      const orderResponse = await axios.post(`${process.env.REACT_APP_API_URL}/order/calculate`, { lineItems: calculatedLineItems })
      setOrder(orderResponse.data)
      setLineItems(calculatedLineItems)
      if (orderResponse.data) {
        setNetDue(orderResponse?.data.netAmountDueMoney.amount)
      }
    }
    if (getQuote) {
      calculateOrder()
      setEstimateOpen(true)
    }
  }, [getQuote])

  return (
    <div className='form-container'>
      <span className='heading'>Your reservation</span>
      <div className='reservation-details'>
        <div className='reservation-btn-group'>
          <span className='heading3'>Contact Information</span>
        </div>
        <div className='personal-div'>
          <span className='detail-header'>{`${first} ${last}`}</span>
          <span>{email}</span>
          <span>{phone}</span>
        </div>
      </div>
      <div className='reservation-details'>
        <div className='reservation-btn-group'>
          <span className='heading3'>Event Details</span>
        </div>
        <EventDetails {...eventProps} />
      </div>
      <div className='reservation-details'>
        <div className='reservation-btn-group'>
          <span className='heading3'>Service Package</span>
        </div>
        <div className='detail'>
          <span className='detail-header'>{service.title}</span>
          <div className='whats-included-container'>
            {service.included.map((inclusion, idx) => (
              <div key={'inclusion' + idx} className='package-inclusion'>
                <div className='iconContainer'>
                  <img src={check} alt='check' />
                </div>
                <span>{inclusion}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='reservation-details'>
        <div className='reservation-btn-group'>
          <span className='heading3'>Bar Type</span>
        </div>
        <div className='detail'>
          <span className='detail-header'>{menu.name}</span>
          <div className='whats-included-container'>
            {menu.options.map((option, idx) => (
              <div key={option.type + idx} className='package-inclusion'>
                <div className='iconContainer'>
                  <img src={check} alt='check' />
                </div>
                <span>{option.num + ' ' + option.type} option(s)</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='reservation-details'>
        <div className='reservation-btn-group'>
          <span className='heading3'>Additional Comments</span>
        </div>
        <div className='detail'>
          {/* <span className='detail-header'>{menu.name}</span> */}
          <TextField
            fullWidth
            multiline
            minRows={5}
            placeholder='Tell us anything additional that we should know about your event...'
            value={comments}
            onChange={(e) => {
              setComments(e.target.value)
            }}
            sx={{ marginTop: '1rem' }}
          />
        </div>
      </div>
      {confirming ? (
        <div className='reservation-details' ref={paymentRef}>
          <div className='reservation-btn-group'>
            <span className='heading3'>Payment</span>
          </div>
          <div className='detail'>
            <Payment createPayment={true} />
          </div>
        </div>
      ) : (
        <div className='form-btn-group'>
          <Button
            variant='contained'
            fullWidth
            onClick={() => {
              setGetQuote(true)
            }}
          >
            Calculate My Quote
          </Button>
          <span className='caption'>You will not be charged yet</span>
        </div>
      )}

      {estimateOpen && (
        <EstimateDrawer open={estimateOpen} setOpen={setEstimateOpen} setConfirming={setConfirming} paymentRef={paymentRef} />
      )}
    </div>
  )
}

export default ReservationReview
