import AppBar from '../Navigation/AppBar/AppBar'
import HeroSection from '../HeroSection/HeroSection'
import HowItWorks from '../HowItWorks/HowItWorks'
import AvailableServices from '../Services/AvailableServices'
import Footer from '../Footer/Footer.js'
import { Divider } from '@mui/material'
import Ratings from '../Ratings/Ratings.js'
import Gallery from '../Gallery/Gallery.js'
import './Home.css'

const Home = () => {
  return (
    <div style={{ background: 'white' }}>
      <AppBar />
      <HeroSection />
      <HowItWorks />
      <Divider />
      <AvailableServices />
      <Divider />
      <div className='portfolio-div' id='Portfolio'>
        <Gallery />
        <Ratings />
      </div>
      <Footer />
    </div>
  )
}

export default Home
