// import { useOutletContext } from 'react-router-dom'
import './Subtotal.css'
import { Divider } from '@mui/material'
import dayjs from 'dayjs'
import ThreeDotsWave from '../PaymentOptions/ThreeDotsWave'
const Subtotal = ({ eventDate, paymentMethod, confirmed, order }) => {
  const twoWeeksPrior = dayjs(eventDate).subtract(2, 'week').format('MMM D')

  function formatMoney(number) {
    // Divide by 100 to get the correct decimal place
    const amount = number / 100

    // Format the number to a string with commas and two decimal places
    return amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  return (
    <div>
      {order?.netAmountDueMoney?.amount >= 0 ? (
        <div className='subtotal-dropdown'>
          <div className='cost-breakdown'>
            <div className='subtotal'>
              {/* <span className='heading6'>Total</span> */}
              <span style={{ fontSize: '24px', fontWeight: 'bold' }}>${formatMoney(order?.totalMoney.amount)}</span>
            </div>
            {/* <IconButton onClick={handleExpand}>
              <img src={isOpen ? minusIcon : plusIcon} alt='collaspe-icon' />
            </IconButton> */}
          </div>
          <div>
            <Divider />
            <div className='line-items'>
              {order?.lineItems.map((item, idx) => {
                return (
                  <div className='line-item' key={`item${idx}`}>
                    <div className='line-item-name'>
                      <span className='item-name'>{item.name}</span>
                      <span className='item-variation'>{item.variationName}</span>
                    </div>
                    <span>${formatMoney(item.basePriceMoney.amount)}</span>
                  </div>
                )
              })}
              <div className='line-item'>
                <div className='line-item-name'>
                  <span className='item-name'>Taxes</span>
                </div>
                <span>${formatMoney(order?.totalTaxMoney.amount)}</span>
              </div>
            </div>

            {/* {isPaying && (
              <>
                <Divider />
                <div className='line-items'>
                  <div className='line-item'>
                    <b>
                      <span className='due'>{confirmed}</span>
                    </b>
                    <b>
                      <span className='due'>
                        {paymentMethod === 'full'
                          ? `$${formatMoney(order?.netAmountDueMoney.amount)}`
                          : `$${formatMoney(order?.netAmountDueMoney.amount * 0.3)}`}
                      </span>
                    </b>
                  </div>
                  {paymentMethod === 'deposit' && (
                    <div className='line-item'>
                      <span className=''>{`Due ${twoWeeksPrior}`}</span>
                      <span>{`$${formatMoney(order?.netAmountDueMoney.amount - order?.netAmountDueMoney.amount * 0.3)}`}</span>
                    </div>
                  )}
                </div>
              </>
            )} */}

            <div>
              <Divider />
              <div className='line-items'>
                <div className='line-item'>
                  <b>
                    <span className='due'>{confirmed ? 'Remaining Balance' : 'Due Now'}</span>
                  </b>
                  <b>
                    <span className='due'>{`$${formatMoney(order?.netAmountDueMoney.amount)}`}</span>
                  </b>
                </div>
                {order?.netAmountDueMoney.amount > 0 && paymentMethod === 'deposit' && (
                  <div className='line-item'>
                    <b>
                      <span>{`Due ${twoWeeksPrior}`}</span>
                    </b>
                    <span>{`$${formatMoney(order?.netAmountDueMoney.amount - order?.netAmountDueMoney.amount * 0.3)}`}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='three-dots'>
          <ThreeDotsWave />
        </div>
      )}
    </div>
  )
}

export default Subtotal
