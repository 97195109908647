// eslint-disable-next-line no-unused-vars
import './App.css'
import { ThemeProvider, createTheme } from '@mui/material'
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom'
import Home from './pages/Home.js'
import BookingCarousol from './Carousols/BookingCarousol.js'
import ErrorPage from './pages/ErrorPage.js'
import ReservationAppBar from './Navigation/ReservationAppBar.js'
import { useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { packages } from './Services/data.js'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import Booking from './pages/Booking/Booking.js'
import Confirmed from './pages/Confirmed.js' //comment out
import Dashboard from './Dashboard/Dashboard.js'
// import Dashboard from './Dashboard/Dashboard.js'

const Root = () => {
  // const baseClientUrl = process.env.REACT_APP_VERCEL_URL ? `https://${process.env.REACT_APP_VERCEL_URL}` : 'http://localhost:3000'
  return (
    <div className='main-root'>
      {/* {console.log(process.env.REACT_APP_API_URL)} */}
      {console.log(process.env.REACT_APP_CLIENT_URL)}
      <Outlet />
    </div>
  )
}

/*
TODO: 

 */

const ReservationRoot = () => {
  const twoWeeks = dayjs().add('14', 'day')
  const [currentPage, setCurrentPage] = useState(0)
  const [slideDirection, setSlideDirection] = useState('left')
  const [eventAddress, setEventAddress] = useState('')
  const [eventDate, setEventDate] = useState('')
  const [eventStart, setEventStart] = useState(dayjs(new Date(twoWeeks.year(), twoWeeks.month(), twoWeeks.date(), 17)))
  const [eventEnd, setEventEnd] = useState(dayjs(new Date(twoWeeks.year(), twoWeeks.month(), twoWeeks.date(), 20)))
  const [guestCount, setGuestCount] = useState(50)
  const [service, setService] = useState(packages[0])
  const [menu, setMenu] = useState(packages[0].menus[0])
  const [wines, setWines] = useState([])
  const [beers, setBeers] = useState([])
  const [spirits, setSpirits] = useState([])
  const [recipes, setRecipes] = useState([])
  const [reservationId, setReservationId] = useState(uuidv4())
  const [first, setFirst] = useState('')
  const [last, setLast] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [distance, setDistance] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState('full')
  const [locationRadio, setLocationRadio] = useState(null)
  const [createPayment, setCreatePayment] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [netDue, setNetDue] = useState(0)
  const [comments, setComments] = useState('')
  const [order, setOrder] = useState(null)
  const [customer, setCustomer] = useState(null)

  const handlePrevPage = () => {
    setSlideDirection('right')
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  const handleNextPage = () => {
    setSlideDirection('left')
    setCurrentPage((prevPage) => prevPage + 1)
    window.scrollTo(0, 0)
  }

  const addLineItem = (newItem) => {
    setLineItems((prevItems) => [...prevItems, newItem])
  }

  const formatMoney = (number) => {
    let [integerPart, decimalPart] = number.toFixed(2).split('.')

    // Use a regular expression to add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    // Return the formatted number, including the decimal part if it exists
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart
  }

  const context = {
    currentPage,
    setCurrentPage,
    slideDirection,
    handleNextPage,
    eventAddress,
    setEventAddress,
    eventDate,
    setEventDate,
    eventStart,
    setEventStart,
    eventEnd,
    setEventEnd,
    guestCount,
    setGuestCount,
    service,
    setService,
    menu,
    setMenu,
    wines,
    setWines,
    beers,
    setBeers,
    spirits,
    setSpirits,
    recipes,
    setRecipes,
    reservationId,
    setReservationId,
    first,
    setFirst,
    last,
    setLast,
    email,
    setEmail,
    phone,
    setPhone,
    addLineItem,
    locationRadio,
    setLocationRadio,
    distance,
    setDistance,
    paymentMethod,
    setPaymentMethod,
    formatMoney,
    createPayment,
    setCreatePayment,
    lineItems,
    setLineItems,
    netDue,
    setNetDue,
    comments,
    setComments,
    order,
    setOrder,
    customer,
    setCustomer
  }
  return (
    <div className='reservation-root'>
      <ReservationAppBar handlePrevPage={handlePrevPage} currentPage={currentPage} />
      <Outlet context={context} />
    </div>
  )
}
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#79ac78',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#D0E7D2',
      contrastText: '#79AC78'
    },
    text: {
      primary: '#838383'
    },
    error: {
      main: '#c21f3a'
    }
  },
  typography: {
    fontFamily: 'Cabin Condensed'
  }
})
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Root />} errorElement={<ErrorPage />}>
      <Route index element={<Home />} />

      <Route path='reservations' element={<ReservationRoot />}>
        <Route index element={<Booking />} />
        <Route path=':form/:date/:addr' element={<Booking />} />
        <Route path='service/:serviceName/:menuName' element={<Booking />} />
        <Route path=':id' element={<BookingCarousol />} />

        <Route path='form/:date/:addr/:id' element={<BookingCarousol />} />
        <Route path='service/:serviceName/:menuName/:id' element={<BookingCarousol />} />
      </Route>
      <Route path='confirmed/:id' element={<Confirmed />} />
      <Route path='dashboard/:id' element={<Dashboard />} />
    </Route>
  )
)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <RouterProvider router={router}></RouterProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
