import ConfettiExplosion from 'react-confetti-explosion'
import './Confirmed.css'
import { Button, Divider } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import logo from '../resources/logo/logo.svg'

const Confirmed = () => {
  const { id } = useParams()
  const [reservation, setReservation] = useState({})
  const [order, setOrder] = useState({})

  function formatMoney(number) {
    // Divide by 100 to get the correct decimal place
    const amount = number / 100

    // Format the number to a string with commas and two decimal places
    return amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
  useEffect(() => {
    async function getReservation() {
      const reservationResponse = await axios.get(`${process.env.REACT_APP_API_URL}/reservation/${id}`)
      setReservation(reservationResponse.data)
    }
    getReservation()
  }, [])

  useEffect(() => {
    async function getOrder() {
      const orderResponse = await axios.get(`${process.env.REACT_APP_API_URL}/order/${reservation?.orderId}`)
      setOrder(orderResponse.data.order)
    }
    if (reservation?.orderId) getOrder()
  }, [reservation])

  return (
    <div className='confirmed-div'>
      <ConfettiExplosion particleCount={300} />
      <div className='left-view'>
        <Button
          href={`${process.env.REACT_APP_CLIENT_URL}`}
          fullWidth
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
        >
          <div className='logo-div-confirmed'>
            <img src={logo} />
          </div>
        </Button>

        <div className='confirmed-header'>
          {/* <CheckAnimation /> */}
          <span className='heading'>You&apos;re confirmed!</span>
          <span>
            A confirmation email and payment receipt will be sent to <b>{reservation.email}</b> within 24 hours. We recommend retaining a
            copy for your records.
          </span>
        </div>
        <div>
          <div className='reservation'>
            <div className='reservation-recap'>
              <span className='heading3'>
                {dayjs(reservation.eventDate).format('dddd, MMM DD, YYYY')} (
                {`${dayjs(reservation.eventStart).format('hh:mm A')} - ${dayjs(reservation.eventEnd).format('hh:mm A')}`})
              </span>
              <span>{reservation.eventAddress}</span>
            </div>
            <div className='order-details'>
              <b>
                <span>
                  {reservation.first} {reservation.last}
                </span>
              </b>
              <span>{reservation.email}</span>
              <span>{reservation.phone}</span>
            </div>
            <div className='order-details'>
              <span>
                <b>Order ID:</b> {reservation.orderId}
              </span>
              <span>
                <b>Payment Amount:</b> ${formatMoney(reservation.netDue)}
                {order?.netAmountDueMoney?.amount > 0 &&
                  ` ($${formatMoney(order?.netAmountDueMoney?.amount)}
                 due ${dayjs(reservation.eventDate).subtract(2, 'week').format('M/D')})`}
              </span>
              <span>
                <b>Payment Date:</b> {dayjs(order.updatedAt).format('dddd, MMM DD, YYYY')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Divider orientation='vertical' />
      <div className='right-view'>
        <div className='whats-next'>
          <div>
            {' '}
            <span className='heading'> What&apos;s next?</span>
            <Divider />
          </div>

          <div className='next-item'>
            <span className='heading3'>Sign the contract.</span>
            <span>
              A service contract will be sent via email for review and digital signature. Please complete the signing process to confirm
              your agreement and proceed with the service.
            </span>
            <Button variant='contained' disabled>
              Contract Pending
            </Button>
          </div>
          <div className='next-item'>
            <span className='heading3'>Personalize your bar menu.</span>
            <span>
              Schedule a consultation to make your menu selections, and we&apos;ll assist you in finalizing the details. Afterward,
              we&apos;ll provide a convenient mobile shopping list tailored to your choices.
            </span>
            <Button variant='contained' href='https://calendly.com/mo-mixingmos/phone-consultation?month=2024-10' target='_blank'>
              Schedule Consult
            </Button>
          </div>
          <div className='next-item'>
            <span className='heading3'>Access your dashboard.</span>
            <span>Manage your reservation details anytime from the client dashboard. Access updates and make changes on the go.</span>
            <Button variant='contained' href={`${process.env.REACT_APP_CLIENT_URL}/dashboard/${id}`} target='blank'>
              Go to Dashboard
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Confirmed
