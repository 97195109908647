import { steps } from './data'
import './HowItWorks.css'

import alcoholIcon from '../resources/icons/no-alcohol.svg'
import payIcon from '../resources/icons/paynow.svg'
import cartIcon from '../resources/icons/shopping.svg'
import phoneStock from '../resources/stock/estimate.png'
import { Button } from '@mui/material'
const HowItWorks = () => {
  return (
    <div id='How it Works' className='howItWorks'>
      <div className='how-it-works-steps'>
        <div className='section-stock' />
        <div className='stock-content'>
          <span className='stock-title'>Personalize, pay, and reserve online.</span>
          <span className='stock-description'>
            Our automated system ensures a secure, reliable, and user-friendly experience, allowing you to easily manage your reservation
            with just a few clicks.{' '}
          </span>
        </div>
        <div className={'content'}>
          {steps.map((s) => {
            return (
              <div key={'step' + s.step} className='stepTile'>
                <div className='stepInfo'>
                  <div className='step'>{s.step}</div>
                  <span className='steps-header'>{s.header}</span>
                  <span>{s.description}</span>
                </div>
              </div>
            )
          })}
        </div>
        <div className='consult-btn'>
          <div className='btn-option'>
            <span className='heading3'>Ready to get started?</span>
            <Button variant='contained' href='reservations' target='_blank'>
              Instant Quote
            </Button>
          </div>
          <div className='btn-option'>
            {' '}
            <span className='heading3'>Need more help?</span>
            <Button href='https://calendly.com/mo-mixingmos/phone-consultation' target='_blank' variant='contained'>
              Schedule A Call
            </Button>
          </div>
        </div>
        <div className='need-to-know'>
          <div className='iphone-stock'>
            <img src={phoneStock} alt='phone-stock' />
          </div>

          <div className='highlights'>
            <div className='highlight'>
              <img src={alcoholIcon} alt='no-alcohol' />
              <div className='highlight-text'>
                <span className='highlight-heading'>
                  Alcohol not included with <b>any</b> service.
                </span>
                <span className='highlight-subtext'>
                  You&apos;ll have the chance to specify which products will be served at your bar. Shopping list provided.
                </span>
              </div>
            </div>
            <div className='highlight'>
              <img src={payIcon} alt='pay' />
              <div className='highlight-text'>
                <span className='highlight-heading'>Reserve now, pay later.</span>
                30% non-refundable deposit to reserve, full balance due no later than two weeks prior to your event.
                {/* <a>Read more on our Terms of Service</a> */}
                <span className='highlight-subtext'></span>
              </div>
            </div>
            <div className='highlight'>
              <img src={cartIcon} alt='cart' />
              <div className='highlight-text'>
                <span className='highlight-heading'>Curate the perfect bar menu.</span>

                <span className='highlight-subtext'>
                  After your reservation is confirmed, our specialists will assist you in creating a menu. Access your shopping list
                  directly from your mobile device.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
