import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { useEffect, useState } from 'react'
// import { useOutletContext } from 'react-router-dom'
import Info from './Messages/Info'
import { useParams } from 'react-router-dom'

const GoogleAddressInput = ({ setEventAddress, eventAddress, distance, setDistance }) => {
  //   const { distance, setDistance, setEventAddress } = useOutletContext()
  const [address, setAddress] = useState(eventAddress ? eventAddress : '')
  const [notification, setNotification] = useState({
    title: '',
    message: '',
    type: ''
  })

  const { addr } = useParams()
  useEffect(() => {
    if (addr) {
      handleSelect(addr)
    }
  }, [addr])
  useEffect(() => {
    if (distance) {
      if (distance < 217261) {
        setEventAddress(address)
        if (distance > 48280.3) {
          setNotification({
            title: 'Long distance travel fees apply',
            message: 'Your event is farther than 30 miles away. Additional fees will be included in your cost estimate.',
            type: 'info'
          })
        } else {
          setNotification({
            title: 'Service available',
            message: ' Good news! We are able to service your event.',
            type: 'success'
          })
        }
      } else {
        setNotification({
          title: 'Service unavailable',
          message: `${`We're sorry, we are unable to offer service at this time`}`,
          type: 'error'
        })
      }
    }
  }, [distance])

  const handleSelect = async (value) => {
    setAddress(value)
    try {
      const result = await geocodeByAddress(value)
      const { lat, lng } = await getLatLng(result[0])
      const addressTypes = result[0].types
      const validAddress = addressTypes.find(
        (type) =>
          type == 'street_address' || type == 'colloquial_area' || type == 'colloquial_area' || type == 'premise' || type == 'establishment'
      )
      if (validAddress) {
        calculateDistance({ lat, lng })
        setEventAddress(value)
      } else {
        setNotification({
          title: 'Service unavailable',
          message: `${`We're sorry, we are unable to offer service at this time.`}`,
          type: 'error'
        })
        setEventAddress()
      }
    } catch (error) {
      console.error('Error', error)
    }
  }

  const calculateDistance = (destination) => {
    const originLatLng = new window.google.maps.LatLng(32.48399, -83.73325)
    const destinationLatLng = new window.google.maps.LatLng(destination.lat, destination.lng)

    const distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(originLatLng, destinationLatLng)
    setDistance(distanceInMeters)
    return distanceInMeters
  }

  return (
    <div className='gogole-input' style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
      <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Enter your event address..',
                className: 'address-input',
                style: {
                  fontFamily: 'Cabin Condensed', // Customize font family
                  fontSize: '18px', // Customize font size
                  color: 'var(--text)', // Customize text color
                  //   padding: '10px', // Customize padding
                  borderRadius: '5px', // Customize border radius
                  border: '1px solid #ccc' // Customize border
                }
              })}
            />
            <div>
              {loading ? <div>...loading</div> : null}
              {suggestions.map((suggestion, idx) => {
                const style = {
                  backgroundColor: suggestion.active ? 'var(--primary-light)' : 'white',
                  fontSize: '1rem',
                  padding: '1rem',
                  borderBottom: '1px solid var(--grey-border)'
                }
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })} key={'suggestion' + idx}>
                    {suggestion.description}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {notification.title && (
        <div>
          <Info notification={notification} />
        </div>
      )}
    </div>
  )
}

export default GoogleAddressInput
