import SectionHeader from '../pages/Booking/SectionHeader'
import location from '../resources/icons/location2.svg'
import './AddressValidation.css'
import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import { useOutletContext } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Divider, Button, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material'
import Info from '../Messages/Info'
import GoogleAddressInput from '../GoogleAddressInput'

const AddressValidation = () => {
  const context = useOutletContext()
  const { locationRadio, setLocationRadio } = useOutletContext()

  const [notification, setNotification] = useState({
    title: '',
    message: '',
    type: ''
  })

  useEffect(() => {
    if (locationRadio === 'outdoor') {
      setNotification({
        title: 'Outdoor equipment',
        message: 'Additional fees for outdoor equipment will be included in your cost estimate.',
        type: 'info'
      })
    }
  }, [locationRadio])
  return (
    <div className='form-container'>
      <SectionHeader icon={location} instruction={'Tell us about the venue'} />
      <Divider />
      <span className='header-description'>
        Enter the <b>full</b> address of your venue to check if our services are available. Cities, zip codes, and addresses outside the
        state of Georgia not accepted.
      </span>
      <GoogleAddressInput
        setEventAddress={context.setEventAddress}
        eventAddress={context.eventAddress}
        distance={context.distance}
        setDistance={context.setDistance}
      />

      <div className='form-btn-group'>
        {context.eventAddress && (
          <div className='outdoor-radios'>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              value={locationRadio}
              name='radio-buttons-group'
              onChange={(e) => setLocationRadio(e.target.value)}
            >
              <FormLabel>Where will the bar be located?</FormLabel>
              <FormControlLabel value={'indoor'} control={<Radio />} label='Indoor' />
              <FormControlLabel value={'outdoor'} control={<Radio />} label='Outdoor' />
            </RadioGroup>
          </div>
        )}

        {locationRadio === 'outdoor' && (
          <div>
            <Info notification={notification} />
          </div>
        )}
        <Button variant='contained' className='continue-btn' onClick={context.handleNextPage} disabled={!locationRadio} fullWidth>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default AddressValidation
