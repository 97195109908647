// import reservationIcon from '../resources/icons/reservation.svg'
import chevronLeft from '../resources/icons/chevron-left.svg'
import './ReservationAppBar.css'
import { Link } from 'react-router-dom'
import logo from '../resources/logo/logo.svg'

const ReservationAppBar = ({ handlePrevPage, currentPage }) => {
  return (
    <div className='reservation-app-bar'>
      <div className='left-container'>
        {currentPage > 0 && (
          <div className='back-icon' onClick={() => handlePrevPage()}>
            <img src={chevronLeft} />
          </div>
        )}

        <div className='logo-container'>
          <Link to='/'>
            <img src={logo} alt='logo' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ReservationAppBar
