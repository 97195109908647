import { Button, Divider, Drawer, IconButton } from '@mui/material'
import SocialMediaIcons from '../Navigation/Menu/SocialMediaIcons'
import logo from '../resources/logo/logo.svg'
import './Footer.css'
import { useState } from 'react'
import Policy from '../Policy/Policy'
import closeIcon from '../resources/icons/close.svg'
import phoneIcon from '../resources/icons/phone.svg'
const Footer = () => {
  const [policiesOpen, setPoliciesOpen] = useState(false)

  const handleDrawer = () => {
    setPoliciesOpen(!policiesOpen)
  }
  return (
    <>
      <div className='footer-container'>
        <Drawer open={policiesOpen} anchor='bottom'>
          <div className='policy-drawer'>
            <div className='drawer-heading'>
              <span className='heading'>Terms of Service</span>
              <IconButton onClick={handleDrawer}>
                <img src={closeIcon} alt='close-icon' />
              </IconButton>
            </div>
            <Divider />
            <Policy />
          </div>
        </Drawer>
        <div className='quick-links'>
          <span className='heading3'>Quick Links</span>
          <Button onClick={handleDrawer}>Terms of Service</Button>
          <Button href='reservations'> Get A Quote</Button>
        </div>
        <div className='socials-container'>
          <SocialMediaIcons />
        </div>
        <Button variant='outlined' startIcon={<img src={phoneIcon} alt='start-icon' />} href='tel:4782445055'>
          Contact Support
        </Button>
        <div className='footer-logo-container'>
          <img src={logo} />
        </div>
        <div className='copyright'>
          <span>
            © 2024. All rights reserved. Created by{' '}
            <a href='https://themocreate.com' target='_ blank'>
              MOCREATE{' '}
            </a>
            with ♥.
          </span>
        </div>
      </div>
    </>
  )
}

export default Footer
