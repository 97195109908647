export const steps = [
  {
    step: 1,
    header: 'Tell us about the event.',
    description: 'We’ll need the location, date, time, and expected number of guests.'
  },
  {
    step: 2,
    header: 'Choose a service package.',
    description: 'Our bartenders can present a self-service cocktail station, serve only, or provide an acrylic bar rental with service.'
  },
  {
    step: 3,
    header: 'Pick your bar menu.',
    description: 'Determine the number and variety of drink options to offer at the bar.'
  },
  {
    step: 4,
    header: 'Get your quote',
    description: 'Recieve an itemized cost estimate in real-time.'
  },
  {
    step: 5,
    header: 'Confirm your reservation.',
    description:
      'A 30% deposit is required and applied to the total balance, with the full amount due no later than 2 weeks before the event.'
  },
  {
    step: 6,
    header: 'Customize your bar menu.',
    description: 'Work one-on-one with a seasoned expert to craft the perfect menu tailored to your vision.'
  }
]
