import { policies } from './data'
import './Policy.css'

const Policy = () => {
  return (
    <div className='policy'>
      {policies.map((policy, idx) => {
        return (
          <div key={`policy-item${idx}`} className='policy-item'>
            <span className='policy-title'>{policy.title}</span>
            <span className='policy-description'>{policy.description}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Policy
