import dayjs from 'dayjs'
import { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import { useParams } from 'react-router-dom'
import Info from '../../Messages/Info'
import './AvailabilityCalendar.css'

const AvailabilityCalendar = ({ setEventDate }) => {
  const [dateValue, setDateValue] = useState(null)
  const [disabledDates, setDisabledDates] = useState([])
  const [error, setError] = useState(false)

  const { date } = useParams()

  useEffect(() => {
    if (date) {
      handleDateChange(dayjs(date))
    }
  }, [date])
  useEffect(() => {
    const fetchUnavailableDates = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/availability`)
        const dates = await response.json()
        if (dates.unavailableDates) {
          setDisabledDates(dates.unavailableDates)
        }
      } catch (error) {
        console.error('Error fetching unavailable dates:', error)
        setError(true)
      }
    }
    fetchUnavailableDates()
  }, [])

  const handleDateChange = (value) => {
    setDateValue(value)
    setEventDate(dayjs(value))
  }

  const tileDisabled = ({ date, view }) => {
    const today = dayjs()
    const dateObject = dayjs(date)
    const twoWeeks = today.add(2, 'week')
    if (view === 'month') {
      return dateObject.isBefore(today) || dateObject.isBefore(twoWeeks) || disabledDates.includes(dateObject.format('YYYY-MM-DD'))
    }
    return false
  }

  const tileClassName = ({ date, view }) => {
    const today = dayjs()
    const dateObject = dayjs(date)
    const twoWeeks = today.add(2, 'week')
    if (view === 'month') {
      if (dateObject.isSame(dayjs(dateValue))) {
        return 'selected-date'
      }
      if (dateObject.isBefore(today) || dateObject.isBefore(twoWeeks) || disabledDates.includes(dateObject.format('YYYY-MM-DD'))) {
        return 'unavailable-date'
      }
      return 'available-date'
    }
    return null
  }
  return (
    <div>
      {!error ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', gap: '1rem' }}>
          <Calendar
            onChange={handleDateChange}
            value={dateValue ? new Date(dateValue) : null}
            tileDisabled={tileDisabled}
            tileClassName={tileClassName}
            navigationLabel={null}
            next2Label={null}
            prev2Label={null}
            formatShortWeekday={
              (locale, date) => date.toLocaleDateString(locale, { weekday: 'narrow' }) // 'narrow' gives you "M", "T", "W", etc.
            }
          />
          <span className='caption'>{`Last Updated: ${dayjs().format('M/D/YYYY hh:mm A')}`}</span>
        </div>
      ) : (
        <Info
          notification={{
            title: 'Something went wrong.',
            message: 'Unable to fetch availability calendar. Please try again later.',
            type: 'error'
          }}
        />
      )}
    </div>
  )
}

export default AvailabilityCalendar
